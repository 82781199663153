import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef, githubIndividualOnboardingRouteRef, onePasswordVaultAccessRouteRef, onePasswordVaultCreateRouteRef } from './routes';

export const vendingMachinePlugin = createPlugin({
  id: 'vending-machine',
  routes: {
    root: rootRouteRef,
    individualOnboarding: githubIndividualOnboardingRouteRef,
    onePasswordVaultAccess: onePasswordVaultAccessRouteRef,
    onePasswordVaultCreate: onePasswordVaultCreateRouteRef,
  },
});

export const VendingMachinePage = vendingMachinePlugin.provide(
  createRoutableExtension({
    name: 'VendingMachinePage',
    component: () =>
      import('./components/VendingMachinePage').then(m => m.VendingMachinePage),
    mountPoint: rootRouteRef,
  }),
);