import React from 'react';
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
} from '@backstage/core-components';
import {
  CatalogFilterLayout,
  EntityListProvider,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
  EntityKindPicker,
} from '@backstage/plugin-catalog-react';
import {
  CatalogTable,
  DefaultCatalogPageProps
 } from '@backstage/plugin-catalog';

export const CustomCatalogPage = ({
  columns,
  actions,
  initiallySelectedFilter = 'owned',
  initialKind = 'Component',
}: DefaultCatalogPageProps) => {
  const filters = [
    <EntityKindPicker initialFilter={initialKind} hidden />,
    <EntityTypePicker initialFilter='application' hidden />,
    <UserListPicker initialFilter={initiallySelectedFilter} />,
    <EntityTagPicker />,
  ];
  const table = <CatalogTable columns={columns} actions={actions} />;
  return (
    <PageWithHeader title={`All applications`} themeId="home">
      <Content className='CatalogPageApplications'>
        <ContentHeader title=''>
          <SupportButton>All your software catalog entities</SupportButton>
        </ContentHeader>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters children={filters} options={{drawerBreakpoint: 'xl'}} />
            <CatalogFilterLayout.Content children={table} />
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
};