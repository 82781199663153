import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, lightTheme, darkTheme } from '@backstage/theme';

const baseTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#5ac37d',
    },
    warning: {
      main: '#5ac37d',
    },
    success: {
      main: '#5ac37d',
    },
    info: {
      main: '#fff',
    },
    navigation: {
      ...lightTheme.palette.navigation,
      background: '#317548',
      color: '#fff',
      indicator: '#5ac37d',
      navItem: {
        hoverBackground: '#317548',
      },
    },
    text: {
      primary: '#fff',
    },
    background: {
      default: '#333333',
    },
  },
  fontFamily: 'FortumSansWeb, Roboto, sans-serif',
  defaultPageTheme: 'home',
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        boxShadow: 'unset',
        paddingBottom: theme.spacing(1),
      },
      title: {
        color: '#fff',
        fontWeight: 900,
      },
      subtitle: {
        color: '#fff',
      },
      type: {
        color: '#fff',
      },
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontSize: 'inherit',
        textTransform: 'none',
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#111111',
        },
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none',
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
      },
    },
    CatalogReactUserListPicker: {
      title: {
        textTransform: 'none',
      },
    },
  };
};

export const fortumDarkTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};