import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';
import React from 'react';

const TheEmptyMessage: string = "Empty set of information."

const useStyles = makeStyles(theme => ({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const ExcludeKeys: string[] = ["CfStackName", "SortKey"]

interface IAccountItem {
  key: string;
  value: string;
}

const customLabelText: Record<string, string> = {
  'accountid'     :'account id', 
  'requestorname' :'requestor name', 
  'accountname'   :'account name', 
  'createdat'     :'date created', 
  'lacode'        :'la-code'
}

const customLabelCaption = (labelName:string) => {
  let labelCaption:string = labelName
  try {
    labelCaption=String(customLabelText[labelName]||labelName)
  } catch(e) {
    labelCaption = labelName
  }
  return (labelCaption)
}

const getCloudAccountAnnotations = (entity: Entity) => {
  const annotaions: Record<string, string> = entity.metadata.annotations || {}
  const items: IAccountItem[] = []
  // Filter out cloudaccount entries from annotaions
  Object.entries(annotaions).forEach(([key, value]) => {
      var matchArr: RegExpMatchArray | null = key.match(/(^cloudaccount)\/(.*)/)
    if (matchArr != null && matchArr.length > 0) {
      key = matchArr[2] as string
      if (!(ExcludeKeys.includes(key))) {
        const item: IAccountItem = {
          key: key.toLocaleLowerCase('en-US'),
          value: value,
        }
        items.push(item)
      }
    }
  }
  )
  return items
}

export const isNotACloudAccount = (entity: Entity) =>
  Boolean(!(entity.spec?.type === "sandbox-account"))

export const isACloudAccount = (entity: Entity) =>
  Boolean(entity.spec?.type === "sandbox-account")

const AboutNothingAtAll = () => {
  const classes = useStyles()
  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="About" />
      <Divider />
      <CardContent className={classes.gridItemCardContent}>
        <Grid xs={12} >
          <Grid container>{TheEmptyMessage}
          </Grid>
        </Grid>
      </CardContent>
    </Card>)
}

const CloudAccountCard = (items: IAccountItem[]) => {
  const classes = useStyles()                                
  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="About" />
      <Divider />
      <CardContent className={classes.gridItemCardContent}>
        <Grid xs={12} >
          <Grid container>
            {items.map(accountParameters => (
              <Grid item={true} {...{ xs: 12, sm: 6, lg: 4 }}>
                <Typography variant="h2" className={classes.label}>{customLabelCaption(accountParameters.key)}</Typography>
                <Typography variant="body2" className={classes.value}>{accountParameters.value}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export const AboutCloudAccountCard = () => {
  const { entity } = useEntity()

  if (!isACloudAccount(entity)) return AboutNothingAtAll()

  const items: IAccountItem[] = getCloudAccountAnnotations(entity)

  const type = ((entity.spec || {}).type)?.toString()
  items.push({ key: "type", value: type || "" })

  return CloudAccountCard(items)
}
