import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  Collapse, IconButton,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

type MeasurementType = {
  metricName: string,
  producerId: string,
  value: number
}

const measurementServicePath: string = '/api/cloudwatch/measure'

const sendMeasurement = (measurement: MeasurementType) => {
  const config = useApi(configApiRef);
  const backendBaseUrl: string = config.getString('backend.baseUrl')
  const apiUrl: string = backendBaseUrl + measurementServicePath
  try {
    fetch(apiUrl, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(measurement, null, 0)
    })
  } catch (err) {
    throw ((err as Error))
  }
}

const planviewProjectDetails: string = '/api/planviewprojectdetails/details/'

const useStyles = makeStyles(theme => ({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  information: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
}));

const ExcludeKeys: string[] = [
  "entity_key", "name", "projectid", "members", "title"
]

const WideFields: string[] = ['target', 'desc']

const isWideField = (fieldName: string) => {
  var ans: boolean = false
  if (WideFields.includes(fieldName.toLowerCase())) {
    ans = true
  }
  return ans
}

const widthOfField = (fieldName: string) => {
  return isWideField(fieldName) ? 12 : 4
}

const FieldOrder: string[] = [
  'desc',
  'target',
  'roadmap',
  'backlog',
  'teampage',
  'sponsor',
  'servicemanager',
  'productmanager',
  'productowner',
]

const TheEmptyMessage: string = "This project does not have additional information related to PlanView."
const customLabelText: Record<string, string> = {
  'desc': 'Description',
  'entity_key': 'Entity',
  'productmanager': 'Product manager',
  'productowner': 'Product owner',
  'servicemanager': 'Service manager',
  'leadarchitect': 'Lead architect'
}

type DetailedResponse = { response: Record<string, string>[] }

export const hasGroupDetails = (entity: Entity) => {
  var annotations = entity.metadata.annotations || {}
  Object.keys(annotations).includes('hasprojectdetails')
  return Boolean(Object.keys(annotations).includes('hasprojectdetails'))
}

const detailsKey = (entity: Entity) => {
  const key: string = (entity.metadata.namespace || "default") + "/" + entity.kind + "/" + entity.metadata.name
  return key.toLowerCase()
}

const customLabelCaption = (labelName: string) => {
  let labelCaption: string = labelName
  try {
    labelCaption = String(customLabelText[labelName.toLowerCase()] || labelName)
  } catch (e) {
    labelCaption = labelName
  }
  return (labelCaption)
}

const AboutNothingAtAll = () => {
  const classes = useStyles()
  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="Project details" />
      <Divider />
      <CardContent className={classes.gridItemCardContent}>
        <Grid xs={12} >
          <Grid container>{TheEmptyMessage}
          </Grid>
        </Grid>
      </CardContent>
    </Card>)
}

const GroupDetailsCard = (items: Record<string, string>[]) => {
  const classes = useStyles()

  // Collect fields of intrest
  const fields: Map<string, string> = new Map()
  items.map(detail => {
    if (!(ExcludeKeys.includes(detail.key.toLowerCase()))) {
      fields.set(detail.key.toLowerCase(), detail.value)
    }
  })
  const [open, setOpen] = useState(false);
  return (
    <Card className={classes.gridItemCard}>
      <CardHeader
        title="Project details"
        action={
          <IconButton
            onClick={() => setOpen(!open)}
            aria-label="expand"
            size="small"
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        } />
      <Divider />
      <Collapse in={open} timeout='auto' unmountOnExit>
        <CardContent className={classes.gridItemCardContent}>
          <Grid container={true}>
            {
              // Ordered items 
              (FieldOrder.map(fieldName => {
                try {
                  if (fields.has(fieldName)) {
                    const fieldValue: string = fields.get(fieldName) || "-"
                    fields.delete(fieldName)
                    return (
                      <Grid item={true} {...{ xs: 12, md: 12, lg: widthOfField(fieldName) }}>
                        <Typography variant="h2" className={classes.label}>
                          {customLabelCaption(fieldName)}
                        </Typography>
                        <Typography variant="body2" className={classes.value}>
                          {fieldValue.replace(/(^\")|(\"$)/g, '').replace(/\\n/g, '\n')}
                        </Typography>
                      </Grid>)
                  } else {
                    return (<Typography></Typography>)
                  }
                } catch (err) {
                  return (<Grid item={true} {...{ xs: 12, md: 12, lg: 4 }}>
                    {"Error:" + (err as Error).message}
                  </Grid>)
                }
              }))
            }
            {
              // Unordered items ( leftovers from ordered items)
              Array.from(fields.keys()).map(aKey => {
                try {
                  const fieldValue: string = fields.get(aKey) || "-"
                  return (<Grid item={true} {...{ xs: 12, md: 12, lg: 4 }}>
                    <Typography variant="h2" className={classes.label}>
                      {customLabelCaption(aKey)}
                    </Typography>
                    <Typography variant="body2" className={classes.value}>
                      {fieldValue.replace(/(^\")|(\"$)/g, '').replace(/\\n/g, '\n')}
                    </Typography>
                  </Grid>)
                } catch (err) {
                  return (<Grid item={true} {...{ xs: 12, md: 12, lg: 4 }}>
                    {"Error:" + (err as Error).message}
                  </Grid>)
                }
              })
            }
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}

async function apiPlanViewProjectDetails<T>(apiUrl: string, key: string): Promise<T> {
  return fetch(apiUrl + key, { method: 'GET', cache: "no-cache", headers: { "Content-Type": "application/json" } })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.status.toString() + ", " + response.statusText)
      }
      return response.json()
    })

}

const CollectProjectDetails = (entitykey: string) => {
  const config = useApi(configApiRef);
  const backendBaseUrl: string = config.getString('backend.baseUrl')
  const planviewProjectDetailsApiUrl: string = backendBaseUrl + planviewProjectDetails
  const [projectDetails, setProjectDetails] = useState<Record<string, string>[]>([{ key: "Status", value: "Fetching data..." }])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectDetailsResp = await apiPlanViewProjectDetails<DetailedResponse>(planviewProjectDetailsApiUrl, entitykey)
        setProjectDetails(projectDetailsResp['response'])
      }
      catch (e) {
        setProjectDetails([{ key: "Error", value: (e as Error).message }])
      }
    }
    fetchData()
  }, [entitykey])
  return (projectDetails)
}

export const GroupDetailsPlanViewCard = () => {
  const { entity } = useEntity()
  const entitykey: string = detailsKey(entity)
  if (hasGroupDetails(entity)) {
    sendMeasurement({ metricName: 'BackstageCardVisit', producerId: 'GroupDetailsPlanViewCard', value: 1 })
    return GroupDetailsCard(CollectProjectDetails(entitykey))
  } else {
    return AboutNothingAtAll()
  }
}
