export const fortumTools = [
    {
      title: 'Tech Radar',
      description:
        'Tech Radar is a list of technologies, complemented by an assessment result, called ring assignment.',
      url: '/tech-radar',
      image: '/tech-radar.png',
      tags: ['standards', 'landscape'],
    },
  ];
  