import React from 'react';
import {
  ClockConfig,
  HeaderWorldClock,
  WelcomeTitle,
} from '@backstage/plugin-home';
import { Header } from '@backstage/core-components';
const clockConfigs: ClockConfig[] = [
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'STO',
    timeZone: 'Europe/Stockholm',
  },
  {
    label: 'HEL',
    timeZone: 'Europe/Helsinki',
  },
  {
    label: 'POL',
    timeZone: 'Europe/Warsaw',
  },
];

export const HeaderSection = () => {
  return (
    <Header title={<WelcomeTitle />} pageTitleOverride="Home">
      <HeaderWorldClock clockConfigs={clockConfigs} />
    </Header>
  );
};
