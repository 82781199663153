import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react';

import { Link } from '@backstage/core-components';

export const LACODE_ANNOTATION = 'fortum.tech/LA-Code';

export const LACodeCard = () => {
  const { entity } = useEntity();
  if (!isLACodeAvailable(entity)) return null;
  const LACODE = entity?.metadata.annotations?.[LACODE_ANNOTATION];
  const href = `https://arsclient.fortum.com/arsys/forms/ars/Fortum_A_LogicalApplications?eid=${LACODE}`;
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="LA-Code"></CardHeader>
          <CardContent>
            <Link to={href} target="_blank" rel="noopener">
              {LACODE}
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const isLACodeAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[LACODE_ANNOTATION]);
