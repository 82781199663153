import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'vending-machine',
});

export const githubIndividualOnboardingRouteRef = createSubRouteRef({
  id: 'github-individual-onboarding',
  parent: rootRouteRef,
  path: '/github/individual-onboarding',
});

export const onePasswordVaultAccessRouteRef = createSubRouteRef({
  id: '1password-vault-access',
  parent: rootRouteRef,
  path: '/1password/vault-access',
});

export const onePasswordVaultCreateRouteRef = createSubRouteRef({
  id: '1password-vault-create',
  parent: rootRouteRef,
  path: '/1password/vault-create',
});