import '../App.css';
import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, lightTheme } from '@backstage/theme';

const baseTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#3e9458',
    },
    secondary: {
      main: '#3e9458',
    },
    error: {
      main: '#3e9458',
    },
    warning: {
      main: '#3e9458',
    },
    success: {
      main: '#3e9458',
    },
    info: {
      main: '#3e9458',
    },
    navigation: {
      ...lightTheme.palette.navigation,
      background: '#3e9458',
      color: '#FFFFFF',
      indicator: '#FFFFFF',
      navItem: {
        hoverBackground: '#317548',
      },
    },
    text: {
      primary: '#222222',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  fontFamily: 'FortumSansWeb, Roboto, sans-serif',
  defaultPageTheme: 'home',
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        boxShadow: 'unset',
        paddingBottom: theme.spacing(1),
      },
      title: {
        color: theme.palette.primary.dark,
        fontWeight: 900,
      },
      subtitle: {
        color: theme.palette.primary.dark,
      },
      type: {
        color: theme.palette.primary.dark,
      },
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontSize: 'inherit',
        textTransform: 'none',
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#FFFFFF',
        },
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none',
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
      },
    },
    CatalogReactUserListPicker: {
      title: {
        textTransform: 'none',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    MuiListItemIcon: {
      root:{
        color: '#222222',
      }
    },
  };
};

export const fortumTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};