import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { ExplorePage } from '@backstage/plugin-explore';
import { fortumTheme } from './themes/fortum';
import { fortumDarkTheme } from './themes/fortumdark';

import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInProviderConfig,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { SandboxOrderFormPage } from '@internal/plugin-sandbox-order-form';
import { CloudOrderFormPage } from '@internal/plugin-cloud-order-form';
import { VendingMachinePage } from '@internal/plugin-vending-machine';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { CustomCatalogPage } from './components/catalog/CustomCatalogPage';
import { CustomLibraryPage } from './components/library/CustomLibraryPage';

const microsoftProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft',
  message: 'Sign in using Fortum Microsoft Auth ',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage {...props} auto provider={microsoftProvider} />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
  themes: [
    {
      id: 'fortum',
      title: 'Fortum',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={fortumTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'fortumdark',
      title: 'Fortumdark',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={fortumDarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});
const columns = [
  CatalogTable.columns.createNameColumn(),
  CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createMetadataDescriptionColumn(),
  CatalogTable.columns.createTagsColumn(),
];
const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogPage columns={columns} />
    </Route>
    <Route path="/library" element={<CatalogIndexPage />}>
      <CustomLibraryPage columns={columns} />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id={'digitaldevelopment'}
          title={'Digital Development Tech Radar'}
          subtitle={'Curated by BT Data & Software Development'}
          pageTitle={
            'Recommended technologies used in Fortum Digital Development'
          }
        />
      }
    />
    <Route
      path="/coreit-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id={'coreit'}
          title={'Core IT Tech Radar'}
          subtitle={'Curated by BT Technology Platforms'}
          pageTitle={'Core IT technologies used in Fortum'}
        />
      }
    />
    <Route
      path="/cos-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id={'cos'}
          title={'CoS Tech Radar'}
          subtitle={'Curated by CoS'}
          pageTitle={
            'IT technologies used in CoS'
          }
        />
      }
    />
    <Route
      path="/foresight-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id={'foresight'}
          title={'Foresight Tech Radar'}
          subtitle={'Curated by BT Innovation & Venturing'}
          pageTitle={
            'Key technologies and uncertainties in the Energy industry'
          }
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />

    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/vending-machine/sandbox-order-form" element={<SandboxOrderFormPage />} />
    <Route path="/vending-machine/cloud-order-form" element={<CloudOrderFormPage />} />
    <Route path="/vending-machine" element={<VendingMachinePage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
